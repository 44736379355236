.carousel-images {
    display: flex;
    transition: transform 0.4s ease-in-out;
    padding-bottom: 50px;

    img {
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border-radius: 10px;
    }
}

.carousel-control-prev,
.carousel-control-next {
    background: none;
    color: $gray-dark;
    border: none;
    font-size: 24px;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    z-index: 10;
    width: 30px;
    height: 30px;
    top: auto;
    opacity: 1;
    &:focus {
        color: $gray-dark;
    }
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next {
    right: 0;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
    background: rgba(0, 0, 0, 0.7);
}