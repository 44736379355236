#uncontrolled-tab-example {
    .nav-item {
        
        display: flex;
        align-items: center;

        button {
            border-bottom: 1px solid $success;
            text-align: left;
            color: $black;

            &:disabled {
                border-color: $gray-light;
                color: $gray-light;
            }

            &::before {
                content: '';
                background-color: $success;
                color: $white;
                font-size: 10px;
                padding: 2px 6px;
                border-radius: 10px;
                margin-right: 10px;
            }

            &:disabled {
                border-color: $gray-light;
                &::before {
                    background-color: $gray-light;
                }
            }
        }
        &:nth-child(1) {
            button::before {
                content: '1';
            }
        }
        &:nth-child(2) {
            button::before {
                content: '2';
            }
        }
        &:nth-child(3) {
            button::before {
                content: '3';
            }
        }
        &:nth-child(4) {
            button::before {
                content: '4';
            }
        }
    }
}
