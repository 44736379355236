.main-banner {
    display: block;
    margin: 48px 0 64px;
    padding: 16px;
    background: no-repeat url("./../img/main.png") left top;
    background-size: cover;

    @include media-breakpoint-up(lg) {
        padding: 48px;
    }

    .main-banner-wrapp {
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 24px;
        padding: 7vw;
        text-align: center;
        a,
        button {
            @include media-breakpoint-up(lg) {
                font-size: 24px;
            }
        }

        h3 {
            font-size: 32px;
            line-height: 48px;
            font-weight: 300;
        }
    }

}

.main-description {
    .sub-title {
        font-size: 14px;
    }

    .main-title {
        font-size: 48px;
        line-height: 64px;
        @include media-breakpoint-up(lg) {
            font-size: 64px;
            line-height: 96px;
        }
    }

    img {
        width: 100%;
    }

    .content-item-title {
        font-size: 24px;
        padding: 16px 0;
        color: $green-dark;
    }

    &-right {
        color: $text-grey;
        line-height: 24px;
    }
}