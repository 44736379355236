.modal {
    .modal-dialog {
        .modal-content {
            border-radius: 12px;
        }
        .btn-close {
            border: 2px solid $black;
            padding: 6px;
            opacity: 0.3;
            height: 12px;
            width: 12px;
            margin-top: 10px;
            margin-right: 10px;
            margin-bottom: -20px;
            &:hover {
                opacity: 0.6;
            }
        }
        .modal-header,
        .modal-footer {
            border: 0;
            justify-content: flex-start;
            padding: 16px;
        }
        .modal-footer {
            button {
                &.btn-outline {
                    color: $green-light;
                }
            }

            P {
                color: $text-body-gray;
            }
        }

        .modal-body {
            padding: 16px 32px;
            h4, p {
                color: $green-light;

                &.form-text {
                    color: $text-body-gray;
                }

                &.danger {
                    color: $danger;
                }

                &.congrats-small {
                    font-size: 14px;
                    color: $green-light;
                }

                &.congrats-header,
                &.congrats-text {
                    color: $black;
                }

                &.text-green-dark {
                    color: $dark;
                    font-weight: 300;
                }
            }

        }

        .modal-footer {
            padding: 16px 32px;
        }
    }
}