.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 1);
  padding-left: var(--bs-gutter-x, 1);
  margin-right: auto;
  margin-left: auto;
}