.cfa-table-header {
    background-color: $gray-bg;
    border-radius: 12px;
    align-items: center;
    padding: 10px 0;
}

.detail-cfa-link {
    text-decoration: none;
}

.cfa-table-title {
    box-shadow: 3px 3px 5px $gray-dark;
    border-radius: 8px;
    padding: 15px 20px 15px 30px;
    position: relative;
    color: $black;

    div {
        text-decoration: none;
    }

    &::before {
        content: "";
        height: 100%;
        width: 10px;
        background-color: $primary;
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 8px 0 0 8px;
    }

    &.gray-light {
        &::before {
            background-color: $gray-dark
        }
    }

    &.gray-dark {
        &::before {
            background-color: $text-grey;
        }
    }

    &.success {
        &::before {
            background-color: $success;
        }
    }
    &.not_executed {
        &::before {
            background-color: $danger;
        }
    }

    &.grey {
        background-color: $gray-bg;
        .cfa-table-name {
            font-size: 20px;
        }
        .cfa-table-sub-title {
            font-size: 16px;
        }
        &::before {
            background-color: $gray-bg;
        }
    }
    &.white {
        background-color: $white;
        .cfa-table-name {
            font-size: 20px;
        }
        .cfa-table-sub-title {
            font-size: 16px;
        }
        &::before {
            background-color: $white;
        }
    }
}

.primary-border {
    border: 1px solid $primary;
    border-radius: 4px;
}

.cfa-table-sub-title {
    font-size: 14px;
    margin-bottom: 5px;

    &--new {
        font-size: 14px;
        font-weight: 600;
    }
}

.cfa-operation-day {
    background-color: $white;
    padding: 10px;
    border-radius: 12px;

    .field-img {
        width: 40px;
        height: 40px;
    }

    .cfa-table-name {
        font-weight: normal;
    }

    .cfa-table-title {
        &::before {
            background-color: $gray-dark;
        }

        &.not_executed,
        &.declined {
            &::before {
                background-color: $danger;
            }
        }
        &.active {
            &::before {
                background-color: $success;
            }
        }
    }

    .sell {
        .cfa-table-title {
            &::before {
                background-color: $success;
            }
        }

        .sell-price {
            color: $success;
            
        }
    }

    .sell-price {
        &.decline {
            color: $danger;
        }
    }

    .transfer {
        .cfa-table-title {
            &::before {
                background-color: $danger;
            }
        }

        .transfer-price {
            color: $danger;
        }
    }
}

.cfa-table-name {
    font-weight: bold;
}

.cfa-modal {
    .modal-dialog {
        max-width: 1048px;
    }
}

.ammountOfAccount {
    span {
        font-size: 42px;
        color: $primary;
    }
    button {
        margin-top: -20px;
    }
}
.cfa-add-amount-wrap {
    align-items: center;
    .cfa-add-amount {
        border: 1px solid $green-light;
        border-radius: 16px;
        background-color: $gray-light;
        padding: 10px 30px;
    }

    .cfa-add-amount-summ {
        span.amount {
            font-size: 24px;
            color: $secondary;
        }
    }
}

.dashboard-cfa {
    .nav-tabs {
        border: 1px solid $text-body-gray;
        padding: 2px;
        border-radius: 5px;
        display: inline-flex;

        .nav-item {
            button {
                color: $gray-dark;
                background-color: $white;
                border-color: $white;

                &.active {
                    color: $white;
                    background-color: $green-light;
                    border-color: $green-light;
                    border-radius: 5px;
                    margin-bottom: 0;
                }
            }
        }
    }
}

form {
    .sendAmount {
        .form-label {
            font-size: 24px;
            line-height: 36px;
            color: $green-light;
        }

        input {
            font-size: 16px;
            padding-top: 20px;
            padding-bottom: 20px;
            height: 61px;
            border-color: $green-light;

            &::placeholder {
                color: $text-body-gray;
                opacity: 0.5;
            }
            &.small {
                padding-top: 10px;
                padding-bottom: 10px;
                height: 41px;
            }
        }
    }
    .sell-cfa-label {
        input {
            &::placeholder {
                color: $text-body-gray;
                opacity: 0.5;
            }
            &.small {
                padding-top: 10px;
                padding-bottom: 10px;
                height: 41px;
            }
        }
    }
    .totalAmount {
        .form-label {
            font-size: 24px;
            line-height: 36px;
            color: $gold-dark;
        }

        input {
            font-size: 16px;
            padding-top: 20px;
            padding-bottom: 20px;
            height: 61px;
            border-color: $primary;

            &::placeholder {
                color: $text-body-gray;
            }
        }
    }
}

.cfa-gold-header {
    font-size: 24px;
    line-height: 36px;
    color: $gold-dark;
}

.temlate {
    background: $white;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    aspect-ratio : 1 / 1;

    .template-title {
        flex: 0 0 100%;
        color: $text-body-gray;
    }

    .template-links {
        flex: 0 0 100%;
        align-self: end;
    }
}

.base-template {
    .dark-bg {
        background-color: $gray-dark;
        height: 100%;
    }
    .base-template-form {
        display: flex;
    }
    .nav-pills {
        flex: 0 0 40%;
        display: block;

        .nav-item {
            width: 100%;
            flex: 0 0 100%;
            button {
                color: $black;
                text-align: left;
                border-radius: 0;
                padding: 12px 16px;

                &:disabled {
                    color: $text-body-gray;
                }

                &.active {
                    background-color: rgba(255, 185, 2, 0.3);
                }
            }
        }
    }
    .tab-content {
        flex: 0 0 60%;
        background-color: rgba(109, 150, 116, 0.1);
        padding: 16px;

        textarea::placeholder,
        input::placeholder {
            color: var(--bs-secondary-color);
            opacity: 0.3;
        }
        select:invalid {
            color: $gray-dark
        }
    }
    .filePicker {
        border: 1px solid $gray-dark;
        flex-wrap: nowrap;
    }

    .fileLable {
        color: $text-body-gray;
    }
}

.cfa-status {
    background-color: $text-body-gray;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    color: $white;
    padding: 3px 10px;

    &.confirmation {
        background-color: $gold-light;
    }

    &.done {
        background-color: $success;
    }

    &.cancel {
        background-color: $danger;
    }

    span {
        text-transform: uppercase;
    }
}

.cfa-price {
    font-size: 20px;
    font-weight: bold;
}

/* HTML: <div class="loader"></div> */
.loader {
    width: fit-content;
    font-weight: bold;
    font-family: sans-serif;
    font-size: 30px;
    padding: 0 5px 8px 0;
    background: repeating-linear-gradient(90deg,currentColor 0 8%,#0000 0 10%) 200% 100%/200% 3px no-repeat;
    animation: l3 2s steps(6) infinite;
}
.loader:before {
    content: "Обработка..."
}
@keyframes l3 {to{background-position: 80% 100%}}

.detail-cfa-info {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.cfa-table-detail {
    .cfa-table-name {
        font-size: 13px;
    }
}

.fixed-label {
    font-weight: 500;
}

.cmpleate-price-cfa {
    font-weight: bold;
    font-size: 20px;
}

.buy-cfa-modal {
    margin-top: -35px;
}

.modal-header {
    button {
        z-index: 1;
    }
}

.price-for-cfa {
    font-size: 20px;
    color: $green-dark;
    font-weight: bold;
}

.price-for-cfa-label {
    font-size: 14px;
}

#validationfcountCfa {
    &::placeholder {
        font-size: 16px;
        color: $black;
    }
}

.blockedAmount {
    font-size: 20px;
    span {
        color: $danger;
    }
}

.button-set {
    p {
        color: $text-body-gray !important;
    }
}

.color-gray {
    color: $text-body-gray;
    font-size: 14px;
}