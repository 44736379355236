header {
    .btn-outline-wrap-gold {
        .btn-outline {
            background: $gray-bg;
        }
    }

    .account-nav.navbar {
        border-bottom: 1px solid $green-light;
        padding: 0;

        .nav-link-account {
            display: flex;
            align-items: center;
            color: $black;
            margin: 0 5px;

            &.active {
                border-bottom: 1px solid $green-dark;
                background-color: $white;
                color: $green-light;
            }

            &.disabled {
                color: $gray-dark;
            }
        }

        .container {
            align-items: stretch;
        }

        .header-logo {
            margin: 16px 0;
            img {
                border-radius: 16px;
            }
        }

        .navbar-collapse {
            align-items: stretch;
        }

        .btn-container {
            align-items: center;
        }
    }
    .account-link {
        color: $dark;
        text-decoration: none;
    }

    .search-bar {
        width: 45%;
        align-self: center;
        form {
            position: relative;
            input {
                background-color: $gray-dark;
                padding-left: 60px;
            }
            button {
                position: absolute;
                left: 16px;
                top: 0;
                padding: 6px;
            }
        }
    }

    .header-logo {
        img {
            border-radius: 16px;
        }
    }
}