.load-data {
    padding-top: 40px;
    padding-left: 50px;
    padding-right: 60px;
    position: relative;

    &-or-label {
        color: $white;
        background-color: $green-dark;
        font-size: 22px;
        text-transform: uppercase;
        transform: rotate(-30deg);
        display: inline-block;
        position: absolute;
        top: 5px;
        left: 0;
        padding: 2px 10px;
        border-radius: 10px;
    }

    &-title {
        font-size: 22px;
        margin-bottom: 10px;
    }

    &-link {
        font-size: 16px;
        color: $black;
        text-decoration: none;
        display: flex;
        align-items: center;
        border: 1px solid $green-dark;
        background-color: $white;
        border-radius: 8px;
        padding: 6px 10px;
        margin-bottom: 15px;

    }

    &-sub-text {
        font-size: 14px;
        color: $green-light;
        text-align: right;
        padding-bottom: 40px;
    }

    &-achive {
        position: absolute;
        right: 20px;
        bottom: 10px;
    }
}