html,
body {
	min-height: 100vh;
	letter-spacing: -0.5px;
}

#root {
	min-height: 100vh;
}

.App-body {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

main {
    flex: 1 0 auto; 
}

footer {
    flex-shrink: 0;
}

.hidden {
	opacity: 0;
	height: 0;
	width: 0;
	line-height: 0;
	overflow: hidden;
	padding: 0;
	margin: 0;
}

.download {
	position: absolute;
	top: 45%;
	left: 45%;
}