.btn-primary {
    background: $gold-gradient;
    color: $white;
    &:hover {
        color: $white;
    }

    &:hover {
        background: $gold-gradient-oposit;
    }
}

.btn-secondary {
    background: $gree-gradient;
    color: $white;
    &:hover {
        color: $white;
    }

    &:hover {
        background: $gree-gradien-oposit;
    }
}

.btn-outline-wrap-gold {
    background: linear-gradient($white, $white) padding-box,
              linear-gradient(to right, $gold-gradient-e, $gold-gradient-s) border-box;
    border-radius: 8px;
    border: 1px solid transparent;

    .btn-outline {
        background: transparent;
    }
}

.btn-outline-wrap-green {
    background: linear-gradient($white, $white) padding-box,
              linear-gradient(to right, $green-gradient-e, $green-gradient-s) border-box;
    border-radius: 8px;
    border: 1px solid transparent;

    .btn-outline {
        background: transparent;
    }
}

.btn-outline-wrap-gray {
    background: linear-gradient($white, $white) padding-box,
              linear-gradient(to right, $gray-dark, $gray-dark) border-box;
    border-radius: 8px;
    border: 1px solid transparent;

    .btn-outline {
        background: transparent;
    }
}
