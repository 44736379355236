// Colors
$primary: #FFB902;
$secondary: #0C3A2D;
$success: #24C38E;
$danger: #FF4443;
$info: #0BA5FF;
$light: #EAEAEA;
$dark: #0C3A2D;

// Custom colors
$gold-light: #FFB902;
$gold-dark: #BB8A52;
$green-light: #6D9674;
$green-dark: #0C3A2D;
$gray-light: #EAEAEA;
$gray-dark: #D7D7D7;
$gray-bg: #F3F3F3;
$text-white: #fff;
$text-dark: #000;
$text-dark-green: $dark;
$text-grey: #0C3A2D;
$text-body-gray: #8E8E8E;

$gold-gradient-s: #FFC92A;
$gold-gradient-e: #D87A0B;
$gold-gradient: linear-gradient(to right, $gold-gradient-e, $gold-gradient-s);
$gold-gradient-oposit: linear-gradient(to left, $gold-gradient-e, $gold-gradient-s);

$green-gradient-s: #0C3A2D;
$green-gradient-e: #57775D;
$gree-gradient: linear-gradient(to right, $green-gradient-e, $green-gradient-s);
$gree-gradien-oposit: linear-gradient(to left, $green-gradient-e, $green-gradient-s);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1660px
) !default;
// Headers

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 5.25;
$h2-font-size: $font-size-base * 3;

//Button
$btn-padding-y: 12px;
$btn-padding-x: 12px;
$btn-padding-y-sm: 7px;
$btn-padding-x-sm: 7px;
$btn-border-radius: 8px;
$btn-border-width: 0;