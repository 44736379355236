.organization-links {
    background-color: $gray-bg;

    .organization-link {
        color: $black;
        display: flex;
        align-items: center;
        text-decoration: none;
        
        &.active {
            border-bottom: 1px solid $green-dark;
            background-color: $white;
            color: $green-light;
        }

        &.disabled {
            color: $gray-dark;
        }
    }

    .organization-link-box {
        display: flex;
        height: 84px;
        align-items: stretch;
    }
}

.new-customer-title {
    align-items: stretch;

    .new-customer-data {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 0;
    }

    .rewards-number {
        color: $gold-light;
        font-size: 32px;
        padding-right: 10px;
    }
}

.rewards {
    align-items: center;

    .rewards-link {
        &.disabled {
            color: $gray-dark;
        }
    }
}

.legal-entity-border {
    border: 1px solid $green-dark;
    border-radius: 8px;
    padding: 3px 4px 4px;
    display: inline-block;
}

.faild .legal-entity-border {
    background-color: $danger;
}

.warning .legal-entity-border {
    background-color: $primary;
}

.worksheet {
    border: 1px solid $green-dark;
    background: $white;
    padding: 10px;
    color: $black;
    text-decoration: none;
    border-radius: 10px;
    display: inline-block;
}

.formStatusLable {
    text-transform: uppercase;
    display: flex;
    & > div {
        background-color: $text-body-gray;
        border-radius: 8px;
        color: $white;
        padding: 3px 10px;

        svg {
            color: $white;
        }
    }

    &.checking {
        & > div {
            background-color: $primary;
        }
    }
    &.faild {
        & > div {
            background-color: $danger;
        }
    }
    &.done {
        & > div {
            background-color: $success;
        }
    }
}

.formGroupInsideTitle {
    font-size: 14px;
    color: $green-light;
}

.achive-success {
    position: absolute;
    right: 0;
    top: 20px;
    text-align: right;
    color: $gold-dark;
    
    img {
        margin-top: 10px;
    }
}

.login-container {
    a {
        color: $black;
    }
}

.authCode {
    input {
        border: 1px solid $gold-light;
        background-color: rgba(255, 185, 2, 0.2);
    }
}