footer {
    background: $dark;
    margin-top: 0;
    color: $gray-bg;

    .copy {

        p {
            padding: 0;
            margin: 0;
        }
    }

    .color-white {
        color: $gray-bg;
    }

    .footer-logo {
        img {
            border-radius: 6px;
        }
    }
}

.home-bottom {
    margin-bottom: 160px;
}