.card-title {
    font-size: 24px;
    margin-bottom: 12px;
    color: $green-dark;
}

.card {
    border-color: $green-light;
    border-radius: 16px;
    box-shadow: inset 0 0 10px 0 rgba(109, 150, 116, 0.75);
    background-color: $gray-light;

    &.faild {
        border-color: $danger;
    }

    &.done {
        background-color: $white;
    }

    .card-body {
        .form-label {
            display: block;
        }
        
        textarea::placeholder,
        input::placeholder {
            font-size: 12px;
            color: $gray-dark;
        }

        label.required {
            &::after {
                content: ' *';
                color: $danger;
                font-size: 14px;
            }
        }

        &.card-document {
            min-height: 417px;
            display: flex;
            flex-wrap: wrap;

            .card-document-links {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 20px;
                align-self: end;
                flex: 100%;
                &.filled {
                    align-self: flex-start;
                }
            }

            .filePicker {
                flex-wrap: nowrap;
                padding: 2px 10px;
            }
        }
    }
    .cart-body-lable {
        color: $green-light;
    }
    .body-form-list {
        color: $text-body-gray;
        font-size: 12px;
    }
    .card-divider {
        color: $green-light;
    }
    .card-form-title {
        color: $green-light;
        font-size: 22px;
    }

    .card-link-container {
        background: $white;
        border-radius: 8px;
        padding: 15px 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        a {
            color: $black;
            text-decoration: none;
        }

        .tab-content {
            flex: 0 0 auto;
            width: 100%;
        }

        .nav-tabs {
            border: 1px solid $text-body-gray;
            padding: 2px;
            border-radius: 5px;

            .nav-item {
                button {
                    color: $gray-dark;
                    background-color: $white;
                    border-color: $white;

                    &.active {
                        color: $white;
                        background-color: $green-light;
                        border-color: $green-light;
                        border-radius: 5px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

form {
    label.required {
        &::after {
            content: ' *';
            color: $danger;
            font-size: 14px;
        }
    }
}