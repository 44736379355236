input[type="text"],
input[type="password"] {
    height: 38px;
    border: 1px solid $gray-dark;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;

    &:focus {
        border-color: $gold-light;
        outline: $gold-light;
    }

    &.hidden {
        height: 0;
    }
}

label {
    font-weight: bold;
}
input[type="checkbox"],
input[type="radion"] {
    & + label {
        font-weight: normal;
    }
}

input[type="number"] {
    appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
}


.radio {
    margin: 0.5rem;
    padding-left: 0;
    input[type="radio"] {
        position: absolute;
        opacity: 0;
        + .form-check-label,
        + .radio-label {
            padding-left: 0;
            font-weight: normal;
            cursor: pointer;
            &:before {
                content: '';
                background: transparent;
                border-radius: 100%;
                border: 1px solid $gold-light;
                display: inline-block;
                width: 1.4em;
                height: 1.4em;
                position: relative;
                top: 0;
                margin-right: 1em; 
                vertical-align: top;
                cursor: pointer;
                text-align: center;
                transition: all 250ms ease;
            }
        }
        &:checked {
            + .form-check-label,
            + .radio-label {
                &:before {
                    background-color: $gold-light;
                    box-shadow: inset 0 0 0 4px $gray-light;
                }
            }
        }
        &:focus {
            + .form-check-label,
            + .radio-label {
                &:before {
                    outline: none;
                    border-color: $gold-light;
                }
            }
        }
        &:disabled {
            + .form-check-label,
            + .radio-label {
                &:before {
                box-shadow: inset 0 0 0 4px $gray-dark;
                border-color: darken($gray-dark, 25%);
                background: darken($gray-dark, 25%);
                }
            }
        }
        + .form-check-label,
        + .radio-label {
            &:empty {
                &:before {
                    margin-right: 0;
                }
            }
        }
    }

    .form-check-input[type=checkbox] {
        border-color: $primary;
        height: 22px;
        width: 22px;
        margin: 0 0 5px 0;

        & + label {
            margin-left: 15px;
            cursor: pointer;
        }
    }
}

.filePicker {
    background-color: white;
    padding: 10px 20px;
    border-radius: 8px;
    flex-wrap: wrap;
    border: 1px solid $white;
    flex-wrap: wrap;

    .small-txt {
        font-size: 10px;
        padding-right: 20px;
    }

    img {
        cursor: pointer;
    }
}

.small-txt.small-size {
    font-size: 10px;
    padding-right: 20px;
    color: $text-body-gray;
}


.was-validated .form-control:valid {
    & + .filePicker {
        border-color: $success;
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%2324C38E%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
}

.was-validated .form-control:invalid {
    + .filePicker {
        border-color: $danger;
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23FF4443%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23FF4443%27 stroke=%27none%27/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
}

.form-control:disabled {
    + .filePicker {
        .fileIcon,
        .fileLable {
            opacity: 0.35;
            img {
                cursor: not-allowed;
            }
        }
    }
}

.select-document {
    .select-document-wrap {
        background-color: $white;
        border-radius: 8px;
        padding: 10px;
        height: 100%;
        display: flex;
        flex-wrap: wrap;

        .gray-text {
            font-size: 14px;
            color: $text-body-gray;
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.role-info {
    color: $text-body-gray;
}

.small-inputs {
    .form-control {
        font-size: 14px;
    }
}