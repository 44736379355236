.App {
    .accordion-button {
        height: 38px;
        border: 1px solid $gray-dark;
        border-radius: 8px !important;
        color: $green-light;
        font-size: 14px;
        padding: 6px 16px;

        &:not(.collapsed) {
            background-color: $white;
            color: $green-light;
        }
    }

    .accordion-item {
        background: initial;
        border: 0;
    }

    .accordion-collapse {
        background: $white;
        border: 1px solid $gray-dark;
        border-radius: 8px;
        margin-top: 5px;
    }

    .accordion-body {
        padding: 10px;

        .form-check {
            display: flex;
        }

        label {
            font-size: 12px;
        }

        .form-check-input[type=checkbox] {
            margin: 0;
        }
    }
}