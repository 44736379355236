.harvest-data {
    background: $white;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    .harvest-data-top {
        font-size: 22px;
        color: $black;
        background: no-repeat url("./../img/icons/edit-gray.svg") left 3px;
        padding-left: 40px;
    }

    .harvest-data-bottom {
        align-items: stretch;
    }

    .harvest-data-bottom-item {
        box-shadow: 3px 3px 5px $gray-dark;
        border-radius: 8px;
        padding: 15px 20px 15px 30px;
        position: relative;

        &.detail-carousel-height {
            min-height: 180px;
        }

        &::before {
            content: "";
            height: 100%;
            width: 10px;
            background-color: $gray-dark;
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 8px 0 0 8px;
        }

        &.date-bg {
            background-color: $gray-bg;
        }

        &.remove {
            padding: 15px 10px 15px 20px;
            text-align: center;
        }

        &.info {
            &::before {
                background-color: $primary;
            }
        }

        &.success {
            &::before {
                background-color: $success;
            }
        }

        span {
            display: block;

            &:first-child {
                font-weight: bold;
                margin-bottom: 5px;
            }
        }

        &-info {
            span {
                color: $text-body-gray;
                display: block;

                &:first-child {
                    font-weight: bold;
                    margin-bottom: 5px;
                }
            }
        }
    }
}

.photo-detail-img {
    img {
        height: auto;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border-radius: 10px;
    }
}

.photo-technique-img,
.photo-land-img {
    flex-wrap: wrap;
    img {
        width: 75px;
    }

    &.loaded {
        img {
            width: initial;
            max-width: 150px;
            margin: 0 15px 15px 0;
            border-radius: 8px;
        }

        &.info {
            position: relative;
            img {
                max-height: initial;
                max-width: 100%;
                margin: 0;
            }

            .develop-area {
                position: absolute;
                margin: 15px 0 0 15px;
                padding: 15px;
                border-radius: 8px;
                background-color: rgba(255, 255, 255, 0.8);
            }
        }
    }
}

.photo-production-img {
    flex-wrap: wrap;
    object-fit: cover;
    max-height: 250px;
    overflow: hidden;
    margin-bottom: 40px;
    img {
        overflow: hidden;
        height: auto;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border-radius: 16px;
    }
}

.photo-storage-img{
    flex-wrap: wrap;
    justify-content: end;
    img {
        width: 72px;
    }

    &.loaded {
        img {
            width: 72px;
            height: 72px;
            object-fit: cover;
            border-radius: 8px;
            margin: 0 5px 5px 0;
        }
    }
}

.photo-land-img {
    img {
        margin-top: 20px;
    }
}

.photo-technique-img {
    .loaded {
        img {
            max-width: initial;
            max-height: 83px;
            margin-right: 0;
            max-width: 100%;
        }

        a {
            img {
                margin-right: 15px;
            }
        }

        &.info {
            img {
                max-height: initial;
            }
        }
    }
} 

.text-small {
    font-size: 12px;
}

.text-counter {
    position: absolute;
    right: 20px;
    bottom: 10px;
}

.card-link-container-title {
    font-size: 20px;
    font-weight: bold;
}

.develop-technique {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.1) 100%);
    border-radius: 0 0 16px 16px;
    cursor: pointer;

    &::after {
        content: "";
        display: flex;
        position: absolute;
        top: calc(50% - 8px);
        right: 10px;
        background: no-repeat url("./../img/icons/arrow-down-white.svg") left 3px;
        background-size: 100%;
        width: 24px;
        height: 17px;
    }
    div {
        color: $white;

        &.develop-technique-title {
            font-size: 16px;
            font-weight: bold;
        }
    }
}

.develop-settings {
    position: absolute;
    top: 10px;
    right: 10px;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.1) 100%);
    border-radius: 18px;
}

.border-link {
    border: 1px solid $gray-dark;
    border-radius: 8px;
    &:hover {
        border: 1px solid $gray-dark;
    }
}

.dropdown {
    .badge {
        font-weight: normal;

        .btn-close {
            border: 1px solid $black;
        }
    }
}

.react-multi-carousel-list {
    .react-multiple-carousel__arrow {
        top: auto;
        bottom: 0;
        background: none;

        &::before {
            color: $gray-dark;
            font-size: 25px;
        }

        &:hover {
            &::before {
                color: $text-body-gray;
            }
        }

        &--right {
            right: 20px;
        }
        &--left {
            left: 20px;
        }
    }
}